import React from 'react';
import Helmet from 'react-helmet';
import './index.css';

const IndexPage = () => {
    return (
		<div className='bg'>
			<Helmet>
				<title>Kyle Kramer - Front-End Engineer</title>
				<link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300&display=swap' rel='stylesheet' />
			</Helmet>

			<div>
				<h1>Coming soon..ish...</h1>
				<p>Portfolio / Playground for Front-End Engineer: Kyle Kramer</p>
			</div>
		</div>
    );
};

export default IndexPage;
